import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl, studentBaseUrl } from "../../config/config";
import {
  delete_student_api,
  generate_student_token_api,
  students_list_with_pagination_api,
} from "../../DAL/Students/Students";
import { useNavigate } from "react-router-dom";
import ComponentPopup from "../../components/GeneralComponents/ComponentPopup";
import AddOrUpdateStudent from "../../components/Students/AddOrUpdateStudent";
import ChangePassword from "../../components/Students/ChangePassword";
import ManageClassTime from "../../components/Students/ManageClassTime";
import { useContentSetting } from "../../Hooks/ContentSetting";
import StudentFilter from "../../components/Students/StudentFilter";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import moment from "moment";
import UpdateFeesSetting from "../../components/Students/UpdateFeesSetting";

const EMPTY_FILTER = {
  lesson_book: "all",
  gender: "all",
  is_online: "all",
  status: "all",
  search_text: "",
};

export default function StudentsList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { socket } = useContentSetting();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [isOpenClassTime, setIsOpenClassTime] = useState(false);
  const [openFeesSetting, setOpenFeesSetting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [studentsList, setStudentsList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [openPassword, setOpenPassword] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [filterData, setFilterData] = useState(EMPTY_FILTER);

  const handleOpenPasswordPopup = (data) => {
    setSelectedObject(data);
    setOpenPassword(true);
  };

  const handlePersonalNotes = (data) => {
    navigate(`/students/personal-notes/${data._id}`);
  };

  const handleLeaves = (data) => {
    navigate(`/students/leave-requests/${data._id}`);
  };

  const handleClosePasswordPopup = () => {
    setOpenPassword(false);
    setSelectedObject({});
  };

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawer(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setIsOpenClassTime(false);
    setOpenFeesSetting(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_students_list = async (filter_data) => {
    setIsLoading(true);
    const result = await students_list_with_pagination_api(
      page,
      rowsPerPage,
      filter_data
    );

    if (result.code === 200) {
      localStorage.setItem("students_filter", JSON.stringify(filter_data));
      const data = result.students_list.map((student) => {
        return {
          ...student,
          table_avatar: {
            src: s3baseUrl + student.profile_image,
            alt: student.name,
          },
          created_at: moment(student.createdAt).format("DD-MM-YYYY hh:mm A"),
          whatsapp_number: student.whatsapp ? student.whatsapp : "",
          associate_name: student.associate?.name
            ? student.associate?.name
            : "",
        };
      });
      setStudentsList(data);
      setTotalCount(result.total_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "roll_number_string", label: "Student ID" },
    { id: "name", label: "Name" },
    { id: "gender", label: "Gender", className: "text-capitalize" },
    { id: "birth_date", label: "Birth Date" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
      show_online: true,
      className: "reduce-cell-padding",
    },
    {
      label: "Teachers",
      renderData: (row) => {
        if (row.teachers?.length > 0) {
          return (
            <div>
              {row.teachers.map((teacher) => {
                return <div>{teacher.name}</div>;
              })}
            </div>
          );
        }
      },
    },
    { id: "guardian_name", label: "Guardian" },
    { id: "address", label: "Address" },
    { id: "associate_name", label: "Associate" },
    { id: "status", label: "Status", type: "row_status" },
    { id: "relation", label: "Relation", className: "text-capitalize" },
    { id: "whatsapp_number", label: "Whatsap" },
    { id: "created_at", label: "Created At" },
  ];

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };

  const handleOpenClassTime = (data) => {
    setSelectedObject(data);
    setIsOpenClassTime(true);
  };

  const handleOpenFeesSetting = (data) => {
    setSelectedObject(data);
    setOpenFeesSetting(true);
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const handleChange = (name, value) => {
    setFilterData((old) => {
      return { ...old, [name]: value };
    });
  };

  const searchFunction = () => {
    setPage(0);
    handleCloseFilterDrawer();
    get_students_list(filterData);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setFilterData(EMPTY_FILTER);
    setPage(0);
    handleCloseFilterDrawer();
    get_students_list(EMPTY_FILTER);
  };

  const generate_token = async (row) => {
    const result = await generate_student_token_api(row._id);
    if (result.code === 200) {
      window.open(`${studentBaseUrl}login-by-admin/${result.token}`);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleToDoList = (data) => {
    navigate(`/students/to-do-lists/${data._id}`);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Login As Student",
      icon: "majesticons:login-line",
      handleClick: generate_token,
    },
    {
      label: "Change Password",
      icon: "material-symbols:key-outline",
      handleClick: handleOpenPasswordPopup,
    },
    {
      label: "Update Fees Setting",
      icon: "heroicons:currency-dollar",
      handleClick: handleOpenFeesSetting,
    },
    {
      label: "Manage Class Timings",
      icon: "ion:time-outline",
      handleClick: handleOpenClassTime,
    },
    {
      label: "Personal Notes",
      icon: "codicon:note",
      handleClick: handlePersonalNotes,
    },
    {
      label: "Leave Requests",
      icon: "pepicons-pencil:leave",
      handleClick: handleLeaves,
    },
    {
      label: "To Do List",
      icon: "ic:baseline-list",
      handleClick: handleToDoList,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_student_api(selectedObject._id);
    if (result.code === 200) {
      const data = studentsList.filter(
        (student) => student._id !== selectedObject._id
      );
      setStudentsList(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_student_online_offline = (data) => {
    setStudentsList((old) => {
      return old.map((student) => {
        if (student._id == data._id) {
          return {
            ...student,
            is_online: data.is_online,
          };
        }
        return student;
      });
    });
  };

  useEffect(() => {
    socket.on("student_online", (data) => {
      handle_student_online_offline(data);
    });
    socket.on("student_offline", (data) => {
      handle_student_online_offline(data);
    });
    return () => {
      socket.off("student_online");
      socket.off("student_offline");
    };
  }, []);

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem(`students_filter`);
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
    }
    setFilterData(filter_data);
    get_students_list(filter_data);
  }, [page, rowsPerPage]);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Students List</h2>
                </div>
                <div className="add--button mb-3 text-end">
                  <button className="me-2" onClick={handleOpenFilterDrawer}>
                    Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
                  </button>
                  <button onClick={handleOpenDrawer}>Add Student</button>
                </div>
              </div>

              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={studentsList}
                MENU_OPTIONS={MENU_OPTIONS}
                custom_search={{
                  searchText: filterData.search_text,
                  setSearchText: (value) => {
                    handleChange("search_text", value);
                  },
                  handleSubmit: searchFunction,
                }}
                custom_pagination={{
                  total_count: totalCount,
                  rows_per_page: rowsPerPage,
                  page: page,
                  handleChangePage: handleChangePage,
                  onRowsPerPageChange: handleChangeRowsPerPage,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Update"} Student`}
        componentToPassDown={
          <AddOrUpdateStudent
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            studentsList={studentsList}
            setStudentsList={setStudentsList}
            selectedObject={selectedObject}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={filterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Student Filter"
        componentToPassDown={
          <StudentFilter
            filterData={filterData}
            handleChange={handleChange}
            handleSubmit={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={isOpenClassTime}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Manage Class Timing"
        componentToPassDown={
          <ManageClassTime
            onCloseDrawer={handleCloseDrawer}
            setStudentsList={setStudentsList}
            selectedObject={selectedObject}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={openFeesSetting}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Update Fees Setting"
        componentToPassDown={
          <UpdateFeesSetting
            onCloseDrawer={handleCloseDrawer}
            setStudentsList={setStudentsList}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this student?"}
        handleAgree={handleDelete}
      />

      <ComponentPopup
        openPopup={openPassword}
        setOpenPopup={setOpenPassword}
        title="Change Password"
        componentToPassDown={
          <ChangePassword
            handleClose={handleClosePasswordPopup}
            selectedObject={selectedObject}
          />
        }
      />
    </>
  );
}
