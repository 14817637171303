import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";
import { useContentSetting } from "../../../Hooks/ContentSetting";
import moment from "moment";
import { formatTime } from "../../../utils/constant";
import { useClassRoom } from "../../../Hooks/ClassRoom";
import { logo } from "../../../assets";

export default function ParticipantView(props) {
  const micRef = useRef(null);
  const studentVideo = useRef(null);
  const { studentStream, isPaused } = useClassRoom();

  const { setBoxType, boxType } = props;
  let total_time = 0;
  const { startedMeeting } = useContentSetting();
  if (startedMeeting) {
    const startTime = moment(startedMeeting.call_started_time);
    const endTime = moment(new Date());
    total_time = endTime.diff(startTime, "seconds");
  }
  const [timer, setTimer] = useState(total_time);

  const handleCloseBox = () => {
    setBoxType("CIRCLE");
  };

  useEffect(() => {
    if (studentStream && studentVideo.current) {
      studentVideo.current.srcObject = studentStream;
    }
  }, [studentStream, studentVideo]);

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="meeting-wrapper">
      {boxType === "CIRCLE" ? (
        <div className="change-call-view" onClick={handleCloseBox}>
          <Tooltip title={`Open Call Tools`}>
            <Icon
              fontSize="18"
              className="start-call-icon"
              icon={`ic:sharp-open-with`}
            />
          </Tooltip>
        </div>
      ) : (
        <div className="meeting-top-section">
          <div className="change-call-view" onClick={handleCloseBox}>
            <Tooltip title={`Close Box`}>
              <Icon
                fontSize="18"
                className="start-call-icon"
                icon={`ic:baseline-close`}
              />
            </Tooltip>
          </div>
          <div className="call-timer-section">
            <div className="call-timer-box">{formatTime(timer)}</div>
          </div>
          <div
            className="change-call-view"
            onClick={() => setBoxType(boxType === "BOX" ? "FULL" : "BOX")}
          >
            <Tooltip title={`${boxType === "BOX" ? "Full View" : "Box View"}`}>
              <Icon
                fontSize="18"
                className="start-call-icon"
                icon={`${
                  boxType === "BOX"
                    ? "majesticons:open"
                    : "mdi:arrow-collapse-all"
                }`}
              />
            </Tooltip>
          </div>
        </div>
      )}
      <video ref={studentVideo} autoPlay className="w-100" />
      {isPaused && (
        <div className="image-logo-box">
          <img src={logo} alt="" />
        </div>
      )}
    </div>
  );
}
