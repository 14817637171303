import React from "react";
import ParticipantView from "./ParticipantView";
import { useState } from "react";
import { useClassRoom } from "../../../Hooks/ClassRoom";
import { Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { logo } from "../../../assets";
import { useContentSetting } from "../../../Hooks/ContentSetting";
import { message_by_meeting_api } from "../../../DAL/Messages/Messages";

export default function MeetingView(props) {
  const {
    isIncomingCall,
    isCallAccepted,
    setIsIncomingCall,
    callUser,
    myPeer,
    startCallTune,
    studentStream,
    lastCallID,
    handleStopCall,
    myStream,
  } = useClassRoom();

  const { userInfo, socket } = useContentSetting();
  const [micOn, setMicOn] = useState(true);
  const [isSharedScreen, setIsSharedScreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const { setBoxType, boxType } = props;

  const handle_update_media = (status_type, value) => {
    socket.emit("changed_media", {
      from: userInfo._id,
      meeting_id: myPeer,
      status_type: status_type,
      value: value,
    });
  };

  // const joinMeeting = () => {
  //   setTimeout(async () => {
  //     const result = await message_by_meeting_api(myPeer);
  //     let status = result?.chat_message?.call_status;
  //     if (status === "ringing" || status === "calling") {
  //       let event = "call:no_answered";
  //       if (status === "calling") {
  //         event = "call:unavailable";
  //       }
  //       socket.emit(event, {
  //         to: callUser,
  //         from: userInfo._id,
  //         meeting_id: myPeer,
  //       });
  //       handleStopCall();
  //     }
  //   }, 30000);
  // };

  const acceptCall = () => {
    setIsIncomingCall(false);
    socket.emit("call:accepted", {
      to: callUser,
      from: userInfo._id,
      meeting_id: myPeer,
    });
  };

  const handletoggleMic = () => {
    const audioTracks = myStream.getAudioTracks();
    audioTracks.forEach((track) => (track.enabled = !micOn));
    setMicOn(!micOn);
  };

  const handletoggleShare = () => {
    // toggleScreenShare();
    if (isSharedScreen) {
      setIsSharedScreen(!isSharedScreen);
      handle_update_media("screen_share", false);
    }
  };

  const handleEndCall = () => {
    socket.emit("call:ended", {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
    });
    handleStopCall();
  };

  const handleDeclineCall = () => {
    socket.emit("call:declined", {
      to: callUser,
      from: userInfo._id,
      meeting_id: myPeer,
    });
    handleStopCall();
  };

  const handleCancelCall = () => {
    socket.emit("call:canceled", {
      to: callUser,
      from: userInfo._id,
      meeting_id: myPeer,
    });
    handleStopCall();
  };

  const handletoggleAudio = () => {
    if (!startCallTune.paused) {
      setIsPlaying(false);
      startCallTune.pause();
    } else {
      setIsPlaying(true);
      startCallTune.play();
    }
  };

  return (
    <>
      {lastCallID ? (
        <>
          {/* <Controls /> */}
          {studentStream ? (
            <ParticipantView setBoxType={setBoxType} boxType={boxType} />
          ) : (
            <div className="image-box">
              <img src={logo} alt="" />
            </div>
          )}
        </>
      ) : (
        <div className="image-box">
          <img src={logo} alt="" />
        </div>
      )}
      {boxType !== "CIRCLE" && lastCallID && (
        <div className="icon-box">
          {isIncomingCall ? (
            <Tooltip title={`Join Class`}>
              <div className="success-call-box" onClick={() => acceptCall()}>
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`subway:call-1`}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={`${micOn ? "Mute" : "Unmute"}`}>
              <div className="success-call-box" onClick={handletoggleMic}>
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`${micOn ? "bi:mic-fill" : "vaadin:mute"}`}
                />
              </div>
            </Tooltip>
          )}
          {!isCallAccepted ? (
            <Tooltip title={`${isPlaying ? "Off" : "On"}`}>
              <div className="success-call-box" onClick={handletoggleAudio}>
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`${
                    isPlaying ? "fa-solid:volume-up" : "fa-solid:volume-mute"
                  }`}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              title={`${isSharedScreen ? "Stop Sharing" : "Share Screen"}`}
            >
              <div
                className="success-call-box"
                onClick={() => handletoggleShare()}
              >
                <Icon
                  fontSize="18"
                  className="start-call-icon"
                  icon={`${
                    isSharedScreen
                      ? "fluent:share-screen-stop-28-filled"
                      : "fluent:share-screen-start-28-filled"
                  }`}
                />
              </div>
            </Tooltip>
          )}
          <Tooltip
            title={`${
              isIncomingCall
                ? "Decline Call"
                : isCallAccepted
                ? "Drop Call"
                : "Cancel Call"
            }`}
            onClick={() => {
              if (isIncomingCall) {
                handleDeclineCall();
              } else if (isCallAccepted) {
                handleEndCall();
              } else {
                handleCancelCall();
              }
            }}
          >
            <div className="success-call-box error">
              <Icon
                fontSize="18"
                className="start-call-icon"
                icon="subway:call-3"
              />
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}
