import { CircularProgress } from "@mui/material";
import React from "react";
import { useContentSetting } from "../../Hooks/ContentSetting";
import {
  date_to_display_for_messages,
  markDownLink,
} from "../../utils/constant";
import { logo_image } from "../../assets";
import CustomPopover from "../GeneralComponents/CustomPopover";
import { Icon } from "@iconify/react";
import moment from "moment";
import Markdown from "markdown-to-jsx";
import { s3baseUrl } from "../../config/config";
import ReactAudioPlayer from "./ReactAudioPlayer";

let CALL_INFO = {
  calling: {
    call_text: "Calling...",
    call_icon: "simple-line-icons:call-out",
  },
  ringing: {
    call_text: "Ringing...",
    call_icon: "tdesign:call-1",
  },
  canceled: {
    call_text: "Canceled",
    call_icon: "tdesign:call-off",
  },
  declined: {
    call_text: "Declined",
    call_icon: "heroicons-outline:phone-missed-call",
  },
  started: {
    call_text: "Started",
    call_icon: "icon-park-outline:phone-video-call",
  },
  no_answered: {
    call_text: "No Answered",
    call_icon: "subway:call-3",
  },
  ended: {
    call_text: "Ended",
    call_icon: "ic:baseline-call-end",
  },
  unavailable: {
    call_text: "Unavailable",
    call_icon: "iconamoon:unavailable-fill",
  },
};

export default function ChattingMessages({
  messagesList,
  isLoadingMessages,
  selectedConversation,
  handleMenu,
}) {
  const { userInfo } = useContentSetting();

  if (isLoadingMessages) {
    return (
      <div className="circular-progress circular-progress-message">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <div className="chatting-messages" id="chat-box-body">
      {messagesList.length > 0 ? (
        messagesList.map((message) => {
          var call_text = "";
          var call_icon = "";
          let ended_text = "";
          if (message.message_type === "call") {
            var { call_text, call_icon } = CALL_INFO[message.call_status];
            if (message.call_status === "ended") {
              const startTime = moment(message.call_started_time);
              const endTime = moment(message.call_ended_time);
              const total_time = endTime.diff(startTime, "seconds");
              let hours = Math.floor(total_time / 3600);
              let minutes = Math.floor((total_time % 3600) / 60);
              let seconds = Math.floor((total_time % 3600) % 60);

              if (hours > 0) {
                ended_text = ended_text + `${hours}h `;
              }
              if (minutes > 0) {
                ended_text = ended_text + `${minutes}m `;
              }
              if (seconds > 0) {
                ended_text = ended_text + `${seconds}s`;
              }
            }
          }

          let sender_name = selectedConversation.members.find(
            (member) =>
              member._id === message.sender_id &&
              message.sender_id !== userInfo._id
          );

          return (
            <div
              className={`message-box-view ${
                message.message_type === "call"
                  ? "calling-message"
                  : message.sender_id === userInfo._id
                  ? "outgoing-message"
                  : "incoming-messages"
              }`}
            >
              <div className="message mb-2">
                <div className="message-date mb-1">
                  <span>
                    {`${date_to_display_for_messages(message.createdAt)} ${
                      sender_name?.name ? "(" + sender_name.name + ")" : ""
                    }`}
                  </span>
                </div>
                <div
                  className={`d-flex text-chat-message ${
                    message.message_type === "call"
                      ? "justify-content-center"
                      : message.sender_id === userInfo._id
                      ? "justify-content-end"
                      : ""
                  }`}
                >
                  {message.message_type === "call" ? (
                    <span>
                      <>
                        <Icon fontSize="14" className="me-2" icon={call_icon} />
                        {message.call_status === "ended"
                          ? `${call_text + " " + ended_text}`
                          : call_text}
                      </>
                    </span>
                  ) : (
                    <>
                      <CustomPopover
                        menu={handleMenu(message)}
                        data={message}
                      />
                      {message.recording_url ? (
                        <ReactAudioPlayer
                          audio_url={message.recording_url}
                          duration={message.duration}
                        />
                      ) : (
                        <div className="message-body">
                          {message.images?.length > 0 &&
                            message.images.map((image) => {
                              return (
                                <a
                                  href={s3baseUrl + image}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <img src={s3baseUrl + image} alt="" />
                                </a>
                              );
                            })}
                          {message.message && (
                            <Markdown
                              options={{
                                overrides: {
                                  a: { component: markDownLink },
                                },
                              }}
                            >
                              {message.message}
                            </Markdown>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="no-messages">
          <img src={logo_image} alt="" />
        </div>
      )}
    </div>
  );
}
