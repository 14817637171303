import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { add_user_api, update_user_api } from "../../DAL/Users/Users";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "../../config/config";

export default function AddOrUpdateUser({
  onCloseDrawer,
  setUsersList,
  formType,
  selectedObject,
  usersList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = React.useState("");
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    status: true,
    role: "admin",
  });

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("email", state.email);
    formData.append("status", state.status);
    formData.append("role", state.role);
    if (image) {
      formData.append("profile_image", image);
    }
    if (formType === "ADD") {
      formData.append("password", state.password);
    }
    const result =
      formType === "ADD"
        ? await add_user_api(formData)
        : await update_user_api(formData, state._id);
    if (result.code === 200) {
      let new_user = result.admin_user;
      new_user.table_avatar = {
        src: s3baseUrl + new_user.profile_image,
        alt: new_user.name,
      };
      if (formType === "ADD") {
        setUsersList((usersList) => [new_user, ...usersList]);
      } else {
        const newState = usersList.map((obj) => {
          if (obj._id === state._id) {
            return result.admin_user;
          }
          return obj;
        });
        setUsersList(newState);
      }
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (formType == "EDIT") {
      setState(selectedObject);
      if (selectedObject.profile_image) {
        setPreviews(s3baseUrl + selectedObject.profile_image);
      }
    }
  }, [formType]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="name"
                label="Name"
                variant="outlined"
                name="name"
                value={state.name}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                type="email"
                id="name"
                label="Email"
                variant="outlined"
                name="email"
                value={state.email}
                required
                onChange={handleChange}
              />
            </div>
            {formType === "ADD" && (
              <div className="col-12 col-md-6">
                <TextField
                  className="mt-4 form-control"
                  type="password"
                  id="password"
                  label="Password"
                  variant="outlined"
                  name="password"
                  value={state.password}
                  required={true}
                  onChange={handleChange}
                />
              </div>
            )}
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Status"
                  name="status"
                  className="mui-select"
                  value={state.status}
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Role Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Role Type"
                  name="role"
                  className="mui-select"
                  value={state.role}
                  onChange={handleChange}
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="manager">Manager</MenuItem>
                  <MenuItem value="marketing">Marketing</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-12 mt-3 d-flex">
              {previews && (
                <span className="upload-file-preview mt-3">
                  <span onClick={handleRemove}>x</span>
                  <img src={previews} />
                </span>
              )}
              {previews == "" && (
                <span className="upload-button mt-2">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="icon-button-file"
                    style={{ display: "none" }}
                    name="affirmationImage"
                    onChange={handleUpload}
                  />
                  <label htmlFor="icon-button-file">
                    <CloudUploadIcon />
                  </label>
                </span>
              )}
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
