import { invokeApi } from "../../bl_libs/invokeApi";

export const students_list_api = async () => {
  const requestObj = {
    path: `api/students`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const students_list_with_pagination_api = async (page, limit, data) => {
  const requestObj = {
    path: `api/students/students_list_with_pagination?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const students_list_by_teacher_api = async (teacher_id) => {
  const requestObj = {
    path: `api/students/students_list_by_teacher/${teacher_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const active_students_list_api = async () => {
  const requestObj = {
    path: `api/students/active_students_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_student_api = async (data) => {
  const requestObj = {
    path: `api/students`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const change_student_password_api = async (student_id, data) => {
  const requestObj = {
    path: `api/students/change_password/${student_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_student_api = async (data, student_id) => {
  const requestObj = {
    path: `api/students/${student_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const manage_class_timings_api = async (data, student_id) => {
  const requestObj = {
    path: `api/students/manage_class_timings/${student_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_fees_setting_api = async (data, student_id) => {
  const requestObj = {
    path: `api/students/update_fees_setting/${student_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_student_api = async (student_id) => {
  const requestObj = {
    path: `api/students/${student_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const generate_student_token_api = async (student_id) => {
  const requestObj = {
    path: `api/students/generate_token/${student_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
