import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  delete_student_report_api,
  student_recent_reports_list_api,
} from "../../DAL/StudentReports/StudentReports";
import AddOrUpdateReport from "../../components/StudentReports/AddOrUpdateReport";
import moment from "moment";

export default function StudentRecentReports() {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [reportsList, setReportsList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_reports_list = async () => {
    setIsLoading(true);
    const result = await student_recent_reports_list_api();
    if (result.code === 200) {
      const data = result.reports_list.map((report) => {
        let report_date = moment(report.report_date_time).format(
          "DD-MM-YYYY hh:mm A"
        );
        return {
          ...report,
          student_name: report.student?.name,
          action_info_name: report.action_info?.name,
          report_date: report_date,
          reference: `${
            report.lesson_book === "quran"
              ? "Part (" +
                report.part_mumber +
                ") Verse (" +
                report.verse_number +
                ")"
              : ""
          } `,
          lesson_book_name: `${
            report.lesson_book === "quran"
              ? "Holy Quran"
              : report.lesson_book === "madani_quaida"
              ? "Madani Quadia"
              : "Noorani Quadia"
          } `,
        };
      });
      setReportsList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleOpenLesson = (row) => {
    let url = "/lesson-books/";
    if (row.lesson_book == "quaida") {
      url = `${url}quida-english/page-index-${row.page_number}`;
    } else {
      url = `${url}quran-pak/part-index-${row.part_mumber}/page-index-${row.page_number}`;
    }
    window.open(url);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    { id: "report_date", label: "Report Date", alignRight: false },
    { id: "student_name", label: "Student Name", alignRight: false },
    {
      label: "Open Lesson",
      alignRight: false,
      renderData: (row) => {
        if (row.lesson_book !== "madani_quaida") {
          return (
            <span
              onClick={() => {
                handleOpenLesson(row);
              }}
            >
              Open Lesson
            </span>
          );
        }
      },
      className: "make-link",
    },
    { id: "lesson_book_name", label: "Lesson Book", alignRight: false },
    { id: "page_number", label: "Page Number", alignRight: false },
    { id: "line_number", label: "Line Number", alignRight: false },
    { id: "reference", label: "Reference", alignRight: false },
    { id: "prayer_lesson", label: "Prayer Lesson", alignRight: false },
    {
      id: "action_by",
      label: "Action By Type",
      alignRight: false,
      className: "text-capitalize",
    },
    { id: "action_info_name", label: "Action By Name", alignRight: false },
    { id: "action", label: "Action", alignRight: false, type: "action" },
  ];

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };
  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_student_report_api(selectedObject._id);
    if (result.code === 200) {
      const data = reportsList.filter(
        (report) => report._id !== selectedObject._id
      );
      setReportsList(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_reports_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Student Reports List</h2>
                </div>
                <div className="add--button mb-3 text-end">
                  <button onClick={handleOpenDrawer}>Add Report</button>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={reportsList}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "ADD" ? "Add" : "Update"} Report`}
        componentToPassDown={
          <AddOrUpdateReport
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            reportsList={reportsList}
            setReportsList={setReportsList}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this report?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
