import React, { useContext, useState } from "react";
import { useContentSetting } from "./ContentSetting";
import { useEffect } from "react";
import { tune_file, ended_call_tune } from "../assets";
import Peer from "peerjs";

let micError =
  "Microphone access is denied. Please grant permission in your browser settings.";
let cameraError =
  "'Camera permission is denied. Please enable it in your browser settings.'";

const constraints = {
  video: {
    width: 1280,
    height: 720,
    aspectRatio: 16 / 9,
  },
  audio: true,
};

const CreateClassRoom = React.createContext();
export const useClassRoom = () => useContext(CreateClassRoom);
export function ClassRoom({ children }) {
  const start_call_tune = new Audio(tune_file);
  const ended_call_file = new Audio(ended_call_tune);
  const [myPeer, setMyPeer] = useState(null);
  const [myStream, setMyStream] = useState(null);
  const [studentStream, setStudentStream] = useState(null);
  const [lastCallID, setLastCallID] = useState(null);
  const [autoJoin, setAutoJoin] = useState(true);
  const [isIncomingCall, setIsIncomingCall] = useState(false);
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const [callUser, setCallUser] = useState("");
  const [startCallTune, setStartCallTune] = useState(start_call_tune);
  const [endedCallTune, setEndedCallTune] = useState(ended_call_file);
  const [isPaused, setIsPaused] = useState(true);
  const { socket, userInfo, startedMeeting } = useContentSetting();

  const handleStartCall = async (conversation_id) => {
    const mic = await navigator.permissions.query({ name: "microphone" });
    if (mic.state === "denied") {
      alert(micError);
      return;
    }

    const camera = await navigator.permissions.query({ name: "camera" });
    if (camera.state === "denied") {
      alert(cameraError);
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    const videoTracks = stream.getVideoTracks();
    videoTracks.forEach((track) => (track.enabled = false));
    setMyStream(stream);

    var peer = new Peer();
    setMyPeer(peer);

    peer.on("open", (id) => {
      let callData = {
        to: callUser,
        conversation_id,
        type: "admin",
        sender_id: userInfo._id,
        meeting_id: id,
      };
      socket.emit("user:call", callData);
    });

    peer.on("error", (err) => {
      console.error("PeerJS Error:", err);
    });
  };

  const handleIncommingCall = async (data) => {
    const { from } = data;
    setCallUser(from);
    setIsIncomingCall(true);
  };

  const handleStopAudio = () => {
    startCallTune.pause();
    startCallTune.currentTime = 0;
  };

  const handleCallAccepted = async ({ data }) => {
    const { last_call_id, peer_id } = data;
    if (last_call_id === lastCallID) {
      let call = myPeer.call(peer_id, myStream);
      call.on("stream", (stream) => {
        setStudentStream(stream);
        handleStopAudio();
        setIsIncomingCall(false);
        setIsCallAccepted(true);
      });
    }
  };

  const handleCallRinging = async (data) => {
    const { to, last_call_id } = data;
    if (to === userInfo._id) {
      setLastCallID(last_call_id);
      startCallTune.play();
      startCallTune.addEventListener("ended", () => {
        startCallTune.play();
      });
    }
  };

  const handleVideoPaused = async (data) => {
    setIsPaused(data.is_paused);
  };

  const handleStopCall = () => {
    if (myStream) {
      myStream.getTracks().forEach((track) => track.stop());
      setMyStream(null);
    }
    setStudentStream(null);
    setLastCallID(null);
    if (myPeer) {
      myPeer.destroy();
      setMyPeer(null);
    }
    setIsCallAccepted(false);
    handleStopAudio();
    setCallUser("");
    setIsPaused(true);
    endedCallTune.play();
  };

  useEffect(() => {
    socket.on("incomming:call", handleIncommingCall);
    socket.on("call:accepted", handleCallAccepted);
    socket.on("ringing:call", handleCallRinging);
    socket.on("call:declined", handleStopCall);
    socket.on("call:ended", handleStopCall);
    socket.on("call:no_answered", handleStopCall);
    socket.on("handle_video_paused", handleVideoPaused);

    return () => {
      socket.off("incomming:call", handleIncommingCall);
      socket.off("call:accepted", handleCallAccepted);
      socket.off("ringing:call", handleCallRinging);
      socket.off("call:declined", handleStopCall);
      socket.off("call:ended", handleStopCall);
      socket.off("call:no_answered", handleStopCall);
      socket.off("handle_video_paused", handleVideoPaused);
    };
  }, [socket, myPeer, myStream, lastCallID, studentStream]);

  useEffect(() => {
    if (startedMeeting) {
      setIsCallAccepted(true);
    }
  }, []);

  const collection = {
    myPeer,
    isIncomingCall,
    autoJoin,
    callUser,
    isCallAccepted,
    startCallTune,
    studentStream,
    lastCallID,
    myStream,
    isPaused,
    setCallUser,
    handleStartCall,
    setIsIncomingCall,
    handleStopAudio,
    setLastCallID,
    handleStopCall,
  };

  console.log(isCallAccepted, "isCallAcceptedisCallAccepted");

  return (
    <CreateClassRoom.Provider value={collection}>
      {children}
    </CreateClassRoom.Provider>
  );
}
